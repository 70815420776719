var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _vm.myRole === 0
              ? _c(
                  "div",
                  [
                    _c(
                      "a-steps",
                      { attrs: { current: _vm.current } },
                      _vm._l(_vm.steps, function(item) {
                        return _c("a-step", {
                          key: item.title,
                          attrs: { title: item.title }
                        })
                      }),
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-top": "40px",
                          "padding-bottom": "30px"
                        }
                      },
                      [
                        _vm.current === 0
                          ? _c(
                              "div",
                              [
                                _c("p", [
                                  _vm._v("現在の所属会社 "),
                                  _c("strong", [
                                    _vm._v(
                                      "[" +
                                        _vm._s(_vm.company_id) +
                                        ": " +
                                        _vm._s(_vm.company_name) +
                                        "]"
                                    )
                                  ]),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [
                                      _vm._v(
                                        " ユーザーを作成する会社に所属していることを確認ください。"
                                      )
                                    ]
                                  )
                                ]),
                                _c("p", [
                                  _vm._v(
                                    "テンプレートファイルをダウンロードし、作成したいユーザーをエクセルにて入力してください。作成しましたらページ下部よりファイルをアップロードしてください。"
                                  )
                                ]),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      "font-weight": "bold",
                                      color: "red"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "※こちらのページで作られたユーザーにはメールが飛ばないので注意してください。"
                                    )
                                  ]
                                ),
                                _c(
                                  "a-card",
                                  {
                                    staticStyle: { "margin-bottom": "30px" },
                                    attrs: {
                                      title: "ユーザーの一括作成テンプレート"
                                    }
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "text-center",
                                        staticStyle: { "font-size": "1.2em" }
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "text-primary",
                                            attrs: { href: _vm.templateUrl }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-file-excel-o"
                                            }),
                                            _vm._v(" テンプレートファイル")
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      [
                                        _vm._v(
                                          "\n                    1行目は変更しないでください。"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n                    2行目はサンプルとしていれているので、2行目から書き換えてお使いください。"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n                    ※以下項目の選択肢についてです。それぞれ数値を入力してください。"
                                        ),
                                        _c("br"),
                                        _c("strong", [_vm._v("権限")]),
                                        _vm._v(
                                          "は [1:会社管理者,2:支店管理者,3:ドライバー]"
                                        ),
                                        _c("br"),
                                        _c("strong", [
                                          _vm._v("所属支店は支店ID")
                                        ]),
                                        _vm._v(
                                          "を入力してください。複数支店に所属させる場合は、"
                                        ),
                                        _c("strong", [_vm._v("支店ID")]),
                                        _vm._v(
                                          "をカンマ区切りで入力してください。"
                                        ),
                                        _c("br"),
                                        _c("strong", [_vm._v("支店ID")]),
                                        _vm._v("は ["),
                                        _vm._l(_vm.branchesAll, function(item) {
                                          return _c(
                                            "span",
                                            { key: item.id + item.name },
                                            [
                                              _vm._v(
                                                _vm._s(item.id) +
                                                  ": " +
                                                  _vm._s(item.name) +
                                                  ", "
                                              )
                                            ]
                                          )
                                        }),
                                        _vm._v("]"),
                                        _c("br"),
                                        _c("strong", [_vm._v("解析対象")]),
                                        _vm._v(
                                          "は [0:解析対象にしない,1:解析対象にする]"
                                        ),
                                        _c("br"),
                                        _c("strong", [_vm._v("点呼代行対象")]),
                                        _vm._v(
                                          "は [0:対象にしない,1:対象にする]"
                                        ),
                                        _c("br"),
                                        _c("strong", [_vm._v("代行利用制限")]),
                                        _vm._v(
                                          "は [0:全ての点呼を代行にする,1:平日の日中は代行にしない,2:土日祝は代行にしない]"
                                        ),
                                        _c("br"),
                                        _c("strong", [_vm._v("PLUSの使用")]),
                                        _vm._v("は [0:使用しない,1:使用する]"),
                                        _c("br"),
                                        _c("strong", [_vm._v("点呼権限")]),
                                        _vm._v(
                                          "は [0:権限に従う,1:支店管理者と同等にする]"
                                        ),
                                        _c("br"),
                                        _c("strong", [
                                          _vm._v("運行計画表の被閲覧")
                                        ]),
                                        _vm._v("は [0:不許可,1:許可]"),
                                        _c("br"),
                                        _c("strong", [
                                          _vm._v("連動検知器の使用")
                                        ]),
                                        _vm._v("は [0:使用しない,1:使用する]"),
                                        _c("br")
                                      ],
                                      2
                                    ),
                                    _vm.freeItemUserList
                                      ? _c(
                                          "div",
                                          [
                                            _vm._v(
                                              "\n                    会社ごとに設定した項目は以下の[]内の値を1行目に追記してください。"
                                            ),
                                            _c("br"),
                                            _vm._l(
                                              _vm.freeItemUserList,
                                              function(item) {
                                                return _c(
                                                  "span",
                                                  { key: item.id },
                                                  [
                                                    _vm._v(
                                                      "\n                      [FreeItemUser" +
                                                        _vm._s(item.id) +
                                                        "]: " +
                                                        _vm._s(item.name) +
                                                        " "
                                                    ),
                                                    _c("br")
                                                  ]
                                                )
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      : _vm._e()
                                  ]
                                ),
                                _c(
                                  "a-upload-dragger",
                                  {
                                    attrs: {
                                      name: "file",
                                      multiple: false,
                                      beforeUpload: _vm.beforeUpload
                                    }
                                  },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "ant-upload-drag-icon" },
                                      [
                                        _c("a-icon", {
                                          attrs: { type: "inbox" }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "p",
                                      { staticClass: "ant-upload-text" },
                                      [
                                        _vm._v(
                                          "\n                    クリックしてファイルを選択 または ファイルをドラッグ してください。\n                  "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      { staticClass: "ant-upload-hint" },
                                      [
                                        _vm._v(
                                          "\n                    ファイルはテンプレートに沿って作られたものに限ります。\n                  "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.current === 1
                          ? _c(
                              "div",
                              [
                                _vm.errorCount
                                  ? _c(
                                      "h5",
                                      {
                                        staticClass: "text-danger",
                                        staticStyle: { "margin-bottom": "20px" }
                                      },
                                      [
                                        _vm._v(
                                          "入力にエラーがあります。背景に"
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "wrongCell" },
                                          [_vm._v("色がついているセル")]
                                        ),
                                        _vm._v("を確認してください。")
                                      ]
                                    )
                                  : _vm._e(),
                                _c("a-table", {
                                  staticStyle: { "margin-bottom": "30px" },
                                  attrs: {
                                    columns: _vm.columns,
                                    "data-source": _vm.excelData,
                                    rowKey: "メールアドレス",
                                    pagination: false
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "branches",
                                        fn: function(branches) {
                                          return _c("span", {}, [
                                            _vm._v(
                                              _vm._s(_vm.branchesName(branches))
                                            )
                                          ])
                                        }
                                      },
                                      {
                                        key: "name",
                                        fn: function(name) {
                                          return _c("span", {}, [
                                            _vm._v(_vm._s(name))
                                          ])
                                        }
                                      },
                                      {
                                        key: "phonetic",
                                        fn: function(phonetic) {
                                          return _c("span", {}, [
                                            _vm._v(_vm._s(phonetic))
                                          ])
                                        }
                                      },
                                      {
                                        key: "email",
                                        fn: function(email) {
                                          return _c("span", {}, [
                                            _vm._v(_vm._s(email))
                                          ])
                                        }
                                      },
                                      {
                                        key: "password",
                                        fn: function(password) {
                                          return _c("span", {}, [
                                            _vm._v(_vm._s(password))
                                          ])
                                        }
                                      },
                                      {
                                        key: "role",
                                        fn: function(role) {
                                          return _c("span", {}, [
                                            _vm._v(
                                              _vm._s(
                                                role == 1
                                                  ? "会社管理者"
                                                  : role == 2
                                                  ? "支店管理者"
                                                  : role == 3
                                                  ? "ドライバー"
                                                  : "1(会社管理者)か2（支店管理者）か3（ドライバー）で入力してください"
                                              )
                                            )
                                          ])
                                        }
                                      },
                                      {
                                        key: "mobile_model",
                                        fn: function(mobile_model) {
                                          return _c("span", {}, [
                                            _vm._v(_vm._s(mobile_model))
                                          ])
                                        }
                                      },
                                      {
                                        key: "mobile_sim",
                                        fn: function(mobile_sim) {
                                          return _c("span", {}, [
                                            _vm._v(_vm._s(mobile_sim))
                                          ])
                                        }
                                      },
                                      {
                                        key: "analyze_target",
                                        fn: function(analyze_target) {
                                          return _c("span", {}, [
                                            _vm._v(
                                              _vm._s(
                                                analyze_target == 0
                                                  ? "対象にしない"
                                                  : analyze_target == 1
                                                  ? "対象にする"
                                                  : "0（対象にしない）か 1（対象にする）で入力してください"
                                              )
                                            )
                                          ])
                                        }
                                      },
                                      {
                                        key: "roll_call_agent",
                                        fn: function(roll_call_agent) {
                                          return _c("span", {}, [
                                            _vm._v(
                                              _vm._s(
                                                roll_call_agent == 0
                                                  ? "対象にしない"
                                                  : roll_call_agent == 1
                                                  ? "対象にする"
                                                  : "0（対象にしない）か、1（対象にする）で入力してください"
                                              )
                                            )
                                          ])
                                        }
                                      },
                                      {
                                        key: "roll_call_count",
                                        fn: function(roll_call_count) {
                                          return _c("span", {}, [
                                            _vm._v(_vm._s(roll_call_count))
                                          ])
                                        }
                                      },
                                      {
                                        key: "roll_call_limited",
                                        fn: function(roll_call_limited) {
                                          return _c("span", {}, [
                                            _vm._v(
                                              _vm._s(
                                                roll_call_limited == 0
                                                  ? "平日日中は代行にする"
                                                  : roll_call_limited == 1
                                                  ? "平日日中は代行にしない"
                                                  : roll_call_limited == 2
                                                  ? "土日祝は代行にしない"
                                                  : "0（平日日中は代行にする）か、1（平日日中は代行にしない）か、2（土日祝は代行にしない）で入力してください"
                                              )
                                            )
                                          ])
                                        }
                                      },
                                      {
                                        key: "roll_call_able",
                                        fn: function(roll_call_able) {
                                          return _c("span", {}, [
                                            _vm._v(
                                              _vm._s(
                                                roll_call_able == 0
                                                  ? "権限に従う"
                                                  : roll_call_able == 1
                                                  ? "支店管理者と同等にする"
                                                  : "0（権限に従う）か、1（支店管理者と同等にする）で入力してください"
                                              )
                                            )
                                          ])
                                        }
                                      },
                                      {
                                        key: "plus_flag",
                                        fn: function(plus_flag) {
                                          return _c("span", {}, [
                                            _vm._v(
                                              _vm._s(
                                                plus_flag == 0
                                                  ? "使用しない"
                                                  : plus_flag == 1
                                                  ? "使用する"
                                                  : "0（使用しない）か、1（使用する）で入力してください"
                                              )
                                            )
                                          ])
                                        }
                                      },
                                      {
                                        key: "allow_driving_schedule",
                                        fn: function(allow_driving_schedule) {
                                          return _c("span", {}, [
                                            _vm._v(
                                              _vm._s(
                                                allow_driving_schedule == 0
                                                  ? "不許可"
                                                  : allow_driving_schedule == 1
                                                  ? "許可"
                                                  : "0（不許可）か、1（許可）で入力してください"
                                              )
                                            )
                                          ])
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1703092701
                                  )
                                }),
                                !_vm.errorCount
                                  ? _c("p", [
                                      _vm._v(
                                        "以上のデータで作成してよろしいですか？"
                                      )
                                    ])
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "steps-action" },
                                  [
                                    !_vm.errorCount
                                      ? _c(
                                          "a-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              loading: _vm.createLoading
                                            },
                                            on: { click: _vm.createUser }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    作成する\n                  "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "a-button",
                                      {
                                        staticStyle: { "margin-left": "8px" },
                                        on: { click: _vm.prev }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    戻る\n                  "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.current === 2
                          ? _c("div", [_c("p", [_vm._v("2番目")])])
                          : _vm._e()
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "utils__title mb-3" }, [
      _c("strong", { staticClass: "text-uppercase font-size-16" }, [
        _vm._v("ユーザーの一括作成")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }